import React from 'react';
import { Suspense, lazy } from 'react';
import ReactDOM from 'react-dom';
import './index.css';
import Loading from './loading/Loading.js';

const App = lazy(() => import('./App.js'));

ReactDOM.render(
  <React.StrictMode>
    <Suspense fallback={<Loading/>}>
      <App/>
    </Suspense>
  </React.StrictMode>,
  document.getElementById('root')
);