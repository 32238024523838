import Logo from '../app_img/navLogo.png';
import './Loading.css';

export default function Loading() {

    return(
        <div style={{display: 'flex', flexDirection: 'column', justifyContent: 'center', alignItems: 'center', height: '100vh', background: 'linear-gradient(to bottom, rgb(44, 62, 80), rgb(28, 41, 54)) no-repeat'}}>
            <img src={Logo} alt='Warriors Legacy MMA' style={{margin: '10px'}}></img>
            <div className="lds-default">
                <div></div>
                <div></div>
                <div></div>
                <div></div>
                <div></div>
                <div></div>
                <div></div>
                <div></div>
                <div></div>
                <div></div>
                <div></div>
                <div></div>
            </div>
        </div>
    );
}